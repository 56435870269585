import styled, { css } from 'styled-components';

interface StyledProps {
  $isMainColor?: boolean;
  $isHeadline2?: boolean;
  $isHeadline3?: boolean;
}

export default styled.h1<StyledProps>`
  font-size: ${({ theme }) => theme.headingXXS};
  font-weight: 700;
  color: ${({ theme }) => theme.darkBlue};
  text-align: center;
  @media (min-width: 1025px) {
    font-size: ${({ theme }) => theme.headingL};
    text-align: left;
  }
  ${({ $isMainColor }) =>
    $isMainColor &&
    css`
      color: ${({ theme }) => theme.primary};
    `}
  ${({ $isHeadline2 }) =>
    $isHeadline2 &&
    css`
      font-size: ${({ theme }) => theme.headingXXS};
      @media (min-width: 1025px) {
        font-size: ${({ theme }) => theme.headingS};
        text-align: left;
      }
    `}
    ${({ $isHeadline3 }) =>
    $isHeadline3 &&
    css`
      font-size: ${({ theme }) => theme.headingXS};
      margin-bottom: 20px;
      @media (min-width: 1025px) {
        font-size: ${({ theme }) => theme.headingS};
        text-align: left;
      }
    `}
`;
