import React from 'react';
import styled, { css } from 'styled-components';
import FooterSvg from '@assets/svg/footer.svg';
import scrollTo from '@utils/scrollTo';
import Paragraph from '@components/atoms/Paragraph/Paragraph';
import { Icon } from '@iconify/react';
import redHeart from '@iconify-icons/noto/red-heart';
import arrowUp from '@iconify-icons/fe/arrow-up';
import { animateScroll } from 'react-scroll';

interface StyledProps {
  $isCopyright?: boolean;
  $isPolicyInfo?: boolean;
}

const FooterWrapper = styled.footer`
  position: relative;
  padding: 25px 15px;
  background-color: ${({ theme }) => theme.primary};
`;

const FooterImg = styled.img`
  position: absolute;
  top: 0;
  left: 50%;
  width: 200%;
  height: auto;
  transform: translate(-50%, -99%);

  @media (min-width: 1025px) {
    width: 100%;
  }
`;

const FooterContainer = styled.div`
  max-width: 1250px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 1025px) {
    flex-direction: row;
  }
`;

const FooterMenu = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  @media (min-width: 1025px) {
    width: 80%;
  }
`;

const MenuList = styled.ul`
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  @media (min-width: 1025px) {
    flex-direction: row;
  }
`;
const MenuItem = styled.li`
  padding-bottom: 15px;
  @media (min-width: 1025px) {
    padding-bottom: 0;
    &:not(:last-child) {
      padding-right: 30px;
    }
  }
  a {
    color: #fff;
    text-decoration: none;
    font-weight: ${({ theme }) => theme.medium};
    font-size: ${({ theme }) => theme.bodyM};
  }
`;

const Copyrights = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
`;

const StyledParagraph = styled(Paragraph)<StyledProps>`
  color: #fff;
  margin-bottom: 15px;

  ${({ $isCopyright }) =>
    $isCopyright &&
    css`
      font-size: ${({ theme }) => theme.bodyL};
    `}

  ${({ $isPolicyInfo }) =>
    $isPolicyInfo &&
    css`
      font-size: ${({ theme }) => theme.bodyXS};
      font-weight: ${({ theme }) => theme.regular};
    `}

  svg {
    transform: translateY(25%);
  }
  a {
    color: #fff;
    text-decoration: none;
  }
`;

const FooterInfo = styled.div`
  display: none;
  @media (min-width: 1025px) {
    display: flex;
    width: 20%;
  }
`;

const BackToTop = styled.button`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  outline: none;
  border: none;
  display: flex;
  padding: 0;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  right: -100px;

  a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 30px;
      height: 30px;

      path {
        fill: ${({ theme }) => theme.primary}!important;
      }
    }
  }
`;

const Footer = () => {
  const handleScrollableLink = (e: any, target: string) => {
    e.preventDefault();
    scrollTo(target);
  };

  const handleScrollTop = (e) => {
    e.preventDefault();
    animateScroll.scrollToTop({ duration: 1500, smooth: 'easeOutQuad' });
    console.log('Scrolled!');
  };

  return (
    <FooterWrapper>
      <FooterImg src={FooterSvg} alt="" />
      <FooterContainer>
        <FooterInfo>
          <StyledParagraph $isPolicyInfo>
            Treści znajdujące się na stronie internetowej nie stanowią oferty w
            rozumieniu przepisów prawa i winny być interpretowane wyłącznie jak
            informacja, o których jest mowa w art. 71 Kodeksu Cywilnego.
          </StyledParagraph>
        </FooterInfo>
        <FooterMenu>
          <MenuList>
            <MenuItem>
              <a
                href="/#about"
                onClick={(e) => {
                  handleScrollableLink(e, 'about');
                }}
              >
                O osiedlu
              </a>
            </MenuItem>
            <MenuItem>
              <a
                href="/#gallery"
                onClick={(e) => {
                  handleScrollableLink(e, 'gallery');
                }}
              >
                Galeria zdjęć
              </a>
            </MenuItem>
            <MenuItem>
              <a
                href="/#standard"
                onClick={(e) => {
                  handleScrollableLink(e, 'standard');
                }}
              >
                Standard
              </a>
            </MenuItem>
            <MenuItem>
              <a
                href="/#our-realisations"
                onClick={(e) => {
                  handleScrollableLink(e, 'our-realisations');
                }}
              >
                Nasze realizacje
              </a>
            </MenuItem>
            <MenuItem>
              <a
                href="/#contact"
                onClick={(e) => {
                  handleScrollableLink(e, 'contact');
                }}
              >
                Kontakt
              </a>
            </MenuItem>
            <BackToTop>
              <a href="/#hero" onClick={(e) => handleScrollTop(e)}>
                <Icon icon={arrowUp} />
              </a>
            </BackToTop>
          </MenuList>
          <Copyrights>
            <StyledParagraph>
              Stworzone z <Icon icon={redHeart} /> przez{' '}
              <a
                href="https://rend.pro/offer/investment-website/"
                target="blank"
              >
                <b>Rend.Pro</b>
              </a>
            </StyledParagraph>
            <StyledParagraph $isCopyright>
              Copyright @ {new Date().getFullYear()} Eko Wkra
            </StyledParagraph>
          </Copyrights>
        </FooterMenu>
      </FooterContainer>
    </FooterWrapper>
  );
};

export default Footer;
