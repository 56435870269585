import { createGlobalStyle } from 'styled-components';
import '@theme/typography.css';

const GlobalStyles = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
  }

  html {
    font-size: 62.5%;
    overflow-x: hidden;
  }

  body {
    font-size: 1.6rem;
    padding: 0;
    margin: 0;
    font-family: ${({ theme }) => theme.fontFamily};
    overflow-x: hidden;
  }
  p,label,input,textarea{
    font-family: ${({ theme }) => theme.fontFamily};
    font-weight: ${({ theme }) => theme.regular};
  }

  [data-aos="box-animation"] {
  opacity: 0;
  transition-property: opacity;

  &.aos-animate {
    opacity: 1;
  }
}
[data-aos="image-animation"] {
  opacity: 0;
  transform: translateY(-10%);
  transition-property: transform, opacity;

  &.aos-animate {
    opacity: 1;
    transform: translateY(0%);
  }
}
[data-aos="title-animation"] {
  opacity: 0;
  transform: translateY(10%);
  transition-property: transform, opacity;

  &.aos-animate {
    opacity: 1;
    transform: translateY(0%);
  }
}
`;

export default GlobalStyles;
