import { navigate } from 'gatsby';
import { scroller } from 'react-scroll';

const scrollTo = (target) => {
  if (target === 'contact' || target === 'houses') {
    scroller.scrollTo(target, {
      duration: 1500,
      smooth: 'easeOutQuad',
      offset: -100,
    });
  } else if (window.location.pathname !== '/') {
    navigate('/');
    setTimeout(() => {
      scroller.scrollTo(target, {
        duration: 1500,
        smooth: 'easeOutQuad',
        offset: -100,
      });
    }, 500);
  } else {
    scroller.scrollTo(target, {
      duration: 1500,
      smooth: 'easeOutQuad',
      offset: -100,
    });
  }
};

export default scrollTo;
