import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import LoaderGif from '@assets/gif/loader-ekowkra.gif';

const LoaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 100 !important;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Loader = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  setTimeout(() => {
    setIsLoading(false);
  }, 1000);

  return (
    isLoading && (
      <LoaderWrapper>
        <img src={LoaderGif} alt="Loader" />
      </LoaderWrapper>
    )
  );
};

export default Loader;
