import { DefaultTheme } from 'styled-components'

export const theme: DefaultTheme = {
  primary: '#59b4d0',
  secondary: '#bce1ec',
  darkBlue: '#111b27',
  background: '#fff',
  grey: '#e2e2e2',
  lightGrey: '#f8f8f8',
  btnDarkBlue: '#314862',

  fontFamily: "'SF Pro Display', sans-serif",

  headingXL: '72px',
  headingL: '48px',
  headingM: '42px',
  headingS: '34px',
  headingXS: '32px',
  headingXXS: '24px',

  bodyL: '20px',
  bodyM: '18px',
  bodyS: '16px',
  bodyXS: '14px',

  regular: 400,
  medium: 500,
  bold: 700,

};
