import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import scrollTo from '@utils/scrollTo';
import LogoSvg from '@assets/svg/logo-ekowkra.svg';

const StyledWrapper = styled.nav`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  transition: box-shadow 0.6s ease-in-out;
  z-index: 21;

  &.nav--hidden {
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  }
`;

const StyledContainer = styled.div`
  position: relative;
  display: flex;
  z-index: 20;
  justify-content: center;
  align-items: center;
  max-width: 1250px;
  margin: 0 auto;
  height: 100px;
  padding: 0 15px;
  background-color: #fff;

  @media (min-width: 1025px) {
    justify-content: space-between;
  }
`;
const StyledLogo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 140px;
  padding: 10px 0;

  img {
    width: 90%;
  }
`;
const StyledNav = styled.ul`
  display: none;
  align-items: center;
  @media (min-width: 1025px) {
    display: flex;
  }
`;
const StyledNavItem = styled.li`
  margin: 0;
  padding: 0;
  list-style-type: none;
  &:not(:last-child) {
    margin-right: 30px;
  }

  a {
    cursor: pointer;
    text-decoration: none;
    color: ${({ theme }) => theme.darkBlue};
    font-weight: ${({ theme }) => theme.medium};
    &.active {
      color: ${({ theme }) => theme.primary};
    }
  }
`;

const StyledNavItemContact = styled(StyledNavItem)`
  background-color: ${({ theme }) => theme.primary};
  border-radius: 20px 0 20px 0;
  width: 150px;
  height: 40px;

  a {
    color: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const StyledNavMobile = styled.ul`
  list-style-type: none;
  padding: 0;
  position: absolute;
  top: 100px;
  left: 0;
  transform: translateY(-450px);
  width: 100%;
  height: 350px;
  background-color: ${({ theme }) => theme.primary};
  z-index: 19;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.6s ease-out;

  &.nav--open {
    transform: translateY(0%);
  }

  @media (min-width: 1025px) {
    display: none;
  }
`;

const StyledNavItemMobile = styled(StyledNavItem)`
  margin-bottom: 20px;

  &:not(:last-child) {
    margin-right: 0;
  }
  a {
    color: #fff;
  }
`;

const Hamburger = styled.button`
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  cursor: pointer;
  width: 30px;
  height: 26px;
  padding: 0;
  margin: 0 0 0 15px;
  border: none;
  background-color: transparent;
  @media (min-width: 1025px) {
    display: none;
  }
`;

const HamburgerInner = styled.div`
  position: relative;
  width: 30px;
  height: 3px;
  background-color: ${({ theme }) => theme.darkBlue};
  border-radius: 5px;

  &::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 0;
    width: 30px;
    height: 3px;
    background-color: ${({ theme }) => theme.darkBlue};
    transform-origin: top left;
    transition: transform 0.3s ease-out;
  }
  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 30px;
    height: 3px;
    background-color: ${({ theme }) => theme.darkBlue};
    transform-origin: bottom left;
    transition: transform 0.3s ease-out;
  }
  &.nav--open {
    background-color: #fff;
    &::before {
      transform: rotate(45deg);
      transition: transform 0.3s ease-out;
    }
    &::after {
      transform: rotate(-45deg);
      transition: transform 0.3s ease-out;
    }
  }
`;

const Navigation = () => {
  const [navOpen, setNavOpen] = useState(false);
  const [isVisible, setVisible] = useState(true);
  const posY = useRef(5);
  const handleClick = () => {
    setNavOpen(!navOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      let currentPosY = window.scrollY;
      if (posY.current > currentPosY && !isVisible) {
        setVisible(true);
      }
      if (posY.current < currentPosY && isVisible) {
        setVisible(false);
      }
    };
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => window.removeEventListener('scroll', handleScroll);
  }, [isVisible]);

  const handleScrollableLink = (e: any, target: string) => {
    e.preventDefault();
    scrollTo(target);
  };

  const handleScrollableLinkMobile = (e: any, target: string) => {
    e.preventDefault();
    scrollTo(target);
    setNavOpen(false);
  };

  return (
    <>
      <StyledWrapper className={!isVisible ? 'nav--hidden' : ''}>
        <StyledContainer>
          <Hamburger onClick={handleClick}>
            <HamburgerInner
              className={navOpen ? 'nav--open' : ''}
            ></HamburgerInner>
          </Hamburger>
          <StyledLogo>
            <Link to="/">
              <img src={LogoSvg} alt="Logo" />
            </Link>
          </StyledLogo>
          <StyledNav>
            <StyledNavItem>
              <a
                href="/#hero"
                onClick={(e) => {
                  handleScrollableLink(e, 'hero');
                }}
              >
                Start
              </a>
            </StyledNavItem>
            <StyledNavItem>
              <a
                href="/#about"
                onClick={(e) => {
                  handleScrollableLink(e, 'about');
                }}
              >
                O osiedlu
              </a>
            </StyledNavItem>
            <StyledNavItem>
              <a
                href="/#localization"
                onClick={(e) => {
                  handleScrollableLink(e, 'localization');
                }}
              >
                Lokalizacja
              </a>
            </StyledNavItem>
            <StyledNavItem>
              <a
                href="/#houses"
                onClick={(e) => {
                  handleScrollableLink(e, 'houses');
                }}
              >
                Domy
              </a>
            </StyledNavItem>
            <StyledNavItem>
              <a
                href="/#standard"
                onClick={(e) => {
                  handleScrollableLink(e, 'standard');
                }}
              >
                Standard
              </a>
            </StyledNavItem>
            <StyledNavItem>
              <a
                href="/#our-realisations"
                onClick={(e) => {
                  handleScrollableLink(e, 'our-realisations');
                }}
              >
                Nasze realizacje
              </a>
            </StyledNavItem>
            <StyledNavItemContact>
              <a
                href="/#contact"
                onClick={(e) => {
                  handleScrollableLink(e, 'contact');
                }}
              >
                Kontakt
              </a>
            </StyledNavItemContact>
          </StyledNav>
        </StyledContainer>
        <StyledNavMobile className={navOpen ? 'nav--open' : ''}>
          <StyledNavItemMobile>
            <a
              href="/#hero"
              onClick={(e) => {
                handleScrollableLinkMobile(e, 'hero');
              }}
            >
              Start
            </a>
          </StyledNavItemMobile>
          <StyledNavItemMobile>
            <a
              href="/#about"
              onClick={(e) => {
                handleScrollableLinkMobile(e, 'about');
              }}
            >
              O osiedlu
            </a>
          </StyledNavItemMobile>
          <StyledNavItemMobile>
            <a
              href="/#localization"
              onClick={(e) => {
                handleScrollableLinkMobile(e, 'localization');
              }}
            >
              Lokalizacja
            </a>
          </StyledNavItemMobile>
          <StyledNavItemMobile>
            <a
              href="/#houses"
              onClick={(e) => {
                handleScrollableLinkMobile(e, 'houses');
              }}
            >
              Domy
            </a>
          </StyledNavItemMobile>
          <StyledNavItemMobile>
            <a
              href="/#standard"
              onClick={(e) => {
                handleScrollableLinkMobile(e, 'standard');
              }}
            >
              Standard
            </a>
          </StyledNavItemMobile>
          <StyledNavItemMobile>
            <a
              href="/#our-realisations"
              onClick={(e) => {
                handleScrollableLinkMobile(e, 'our-realisations');
              }}
            >
              Nasze realizacje
            </a>
          </StyledNavItemMobile>
          <StyledNavItemMobile>
            <a
              href="/#contact"
              onClick={(e) => {
                handleScrollableLinkMobile(e, 'contact');
              }}
            >
              Kontakt
            </a>
          </StyledNavItemMobile>
        </StyledNavMobile>
      </StyledWrapper>
    </>
  );
};

export default Navigation;
