import React, { useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';
import GlobalStyles from '@theme/GlobalStyles';
import { ThemeProvider } from 'styled-components';
import { theme } from '@theme/theme';
import Navigation from '@components/organisms/Navigation/Navigation';
import Footer from '@components/organisms/Footer/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';
import CookieNotice from '@rendpro/react-cookie-notice';
import Loader from '@components/molecules/Loader/Loader';
import { graphql, useStaticQuery } from 'gatsby';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import { css } from 'styled-components';
import MetaImg from '@assets/images/1_4K.png';

const BasicTemplate = ({ children, title }) => {
  useLayoutEffect(() => {
    setTimeout(() => {
      AOS.init({
        offset: 200,
        duration: 800,
        easing: 'ease-out-quad',
        anchorPlacement: 'center-center',
        once: true,
      });
    }, 1000);
  });

  const SEO: any = useStaticQuery(query);

  return (
    <>
      <Helmet>
        <title>
          {title
            ? `${title} | Ekowkra.pl`
            : `${SEO.datoCmsMetaSeo.pageTitle} | Ekowkra.pl`}
        </title>
        <meta name="description" content={SEO.datoCmsMetaSeo.metaDescription} />
        <meta
          property="og:image"
          content={SEO.datoCmsMetaSeo.ogImage.fixed.src}
        />
        <html lang="pl" />
        <meta
          name="google-site-verification"
          content="_aQWI9Kf-eIPl3g8w3SLGBCeyrvEkBeTx8j_-iz6eUE"
        />
        <meta
          name="facebook-domain-verification"
          content="eo9gfysqtdah7kyy64mh5r0pqyuf1v"
        />
      </Helmet>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Loader />
        <Navigation />
        <main>{children}</main>
        <Footer />
        <CookieNotice
          text={`Ta strona używa plików cookie w celu usprawnienia i ułatwienia dostępu do serwisu oraz prowadzenia danych statystycznych. Dalsze korzystanie z tej witryny oznacza akceptację tego stanu rzeczy.`}
          buttonText="Akceptuję cookies"
          backgroundColor="rgb(255, 255, 255)"
          linksColor="rgb(89, 180, 208)"
          buttonBackgroundColor="rgb(89, 180, 208)"
          foregroundColor="rgb(17, 27, 39)"
          buttonColor="rgb(255, 255, 255)"
          links={[
            { name: 'Polityka prywatności', link: '/polityka-prywatnosci' },
            {
              name: 'Jak wyłączyć cookies?',
              link: 'https://jakwylaczyccookie.pl/jak-wylaczyc-pliki-cookies/',
            },
            {
              name: 'Cyberbezpieczeństwo',
              link: 'https://nety.pl/cyberbezpieczenstwo',
            },
          ]}
          customStyles={css`
            & {
              z-index: 2147483645;
            }
          `}
        />
        <MessengerCustomerChat
          pageId="110851051087056"
          appId={process.env.FACEBOOK_CHAT_APPID}
          language="pl_PL"
        />
      </ThemeProvider>
    </>
  );
};

const query = graphql`
  query MyQuery {
    datoCmsMetaSeo {
      metaDescription
      pageTitle
      ogImage {
        fixed(width: 1200) {
          src
        }
      }
    }
  }
`;

export default BasicTemplate;
